import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { generateObfuscatedLink } from "../helpers/SEO"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import IconButton from "@material-ui/core/IconButton"

import CloseIcon from "@material-ui/icons/Close"

import { hideProSubscribeDialog } from "../actions"

class UECProSubscribeDialog extends Component {
    render() {
        const { pro_subscribe_dialog_opened, config, direct } = this.props

        return (
            <Dialog
                open={pro_subscribe_dialog_opened ? true : false}
                onClose={() => this.props.hideProSubscribeDialog()}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <IconButton
                        aria-label="Close"
                        style={{ position: "absolute", right: 5, top: 5 }}
                        onClick={() => this.props.hideProSubscribeDialog()}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            fontFamily: "Lato, Roboto, Helvetica, Arial, sans-serif",
                            fontSize: 24,
                            fontWeight: "bold",
                            color: "#0db3ac",
                            margin: "auto",
                            textAlign: "center",
                            marginBottom: 20
                        }}
                    >
                        Accéder à la plateforme
                    </div>
                    <DialogContentText
                        id="alert-dialog-description"
                        style={{ fontWeight: 500, textAlign: "center" }}
                    >
                        <div style={{ marginBottom: 15 }}>
                            Vous un êtes un professionnel de l'immobilier ?{" "}
                            <br />
                            Inscrivez-vous pour proposer un emplacement à cet
                            acquéreur.
                        </div>
                        {generateObfuscatedLink(
                            "https://" + config.appDomain,
                            "Déjà inscrit ?",
                            "uec-button",
                            { width: 150, margin: 10 }
                        )}
                        {generateObfuscatedLink(
                            `/professionnels-immobilier${
                                direct ? "/inscription" : ""
                            }`,
                            "Inscription",
                            "uec-button",
                            { width: 150, margin: 10 }
                        )}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = ({ homepage }) => {
    const { pro_subscribe_dialog_opened } = homepage

    return {
        pro_subscribe_dialog_opened
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ hideProSubscribeDialog }, dispatch)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UECProSubscribeDialog)
