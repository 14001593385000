import React, { Component, Fragment } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Carousel } from "react-responsive-carousel"
import moment from "moment"
import "moment/locale/fr"

import activities from "../datas/Activities"
import typesURLMatching from "../datas/typesURLMatching"
import ProspectionCard from "../components/ProspectionCard"

import { lastProspectionsFetch } from "../actions"

class LastProspections extends Component {
    componentDidMount() {
        if (!this.props.ssr) {
            this.props.lastProspectionsFetch()
        }
    }

    render() {
        const {
            last_prospections,
            last_prospections_fetching,
            last_prospections_fetched,
            last_prospections_error,
            ssr,
            isState
        } = this.props

        return (
            <Fragment>
                <section
                    className="prospections container-fluid mr-md-custom2"
                    style={{ marginTop: 60 }}
                >
                    <div className="row">
                        <div className="col-md-12">
                            <h2
                                style={{
                                    fontFamily:
                                        "Lato, Roboto, Helvetica, Arial, sans-serif",
                                    color: "black",
                                    margin: "auto",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: 32
                                }}
                            >
                                Les dernières recherches
                            </h2>
                        </div>
                    </div>
                    <div className="hideDesktop">
                        <div style={{ marginTop: 25, marginBottom: 25 }}>
                            <Carousel
                                showThumbs={false}
                                showArrows={false}
                                showStatus={false}
                            >
                                {last_prospections.map((prospection, i) => {
                                    const rest_surface = prospection.rest_surface
                                    if (!rest_surface) {
                                        return null
                                    }
                                    const currentPosition = prospection.userinfo ? (
                                        prospection.userinfo.currentPosition ===
                                        "Autre"
                                            ? prospection.userinfo
                                                  .customCurrentPosition
                                            : prospection.userinfo
                                                  .currentPosition) : ""
                                    const views = prospection.stats
                                    const calls = prospection.phone_stats
                                    const proposals = prospection.proposal_stats
                                    const funding =
                                        prospection.funding ||
                                        (prospection.userinfo && prospection.userinfo.status === 2)
                                    const lease = prospection.lease
                                    const lease_attributes =
                                        prospection.lease_attributes
                                    const price = prospection.price
                                    const price_attributes =
                                        prospection.price_attributes
                                    const display_number =
                                        prospection.display_number
                                    const date = moment(
                                        prospection.last_modified_ts
                                    )
                                        .locale("fr")
                                        .format("Do MMMM YYYY")
                                    const prospection_id =
                                        prospection.prospection_id
                                    const reference_id =
                                        prospection.reference_id
                                    const id = prospection.id
                                    const location = prospection.location
                                    const locations = prospection.locations
                                    const activity =
                                        prospection.userinfo &&
                                        prospection.userinfo.activityBrands
                                            ? activities.filter(
                                                  activity =>
                                                      activity.key ===
                                                      prospection.userinfo
                                                          .activityBrands
                                              )[0].name
                                            : prospection.activity_type
                                                ? activities.filter(
                                                      activity =>
                                                          activity.key ===
                                                          prospection.activity_type
                                                  )[0].name
                                                : ""
                                    const description = prospection.description
                                    const user_status = prospection.user_status

                                    return (
                                        <ProspectionCard
                                            key={i}
                                            id={id}
                                            prospection_id={prospection_id}
                                            reference_id={reference_id}
                                            activity={activity}
                                            rest_surface={rest_surface}
                                            date={date}
                                            description={description}
                                            proposal_stats={proposals}
                                            phone_stats={calls}
                                            view_stats={views}
                                            funding={funding}
                                            lease={lease}
                                            lease_attributes={lease_attributes}
                                            price={price}
                                            price_attributes={price_attributes}
                                            display_number={display_number}
                                            location={location}
                                            locations={locations}
                                            user_status={user_status}
                                            style={{
                                                margin: "auto",
                                                textAlign: "left",
                                                maxWidth: 280
                                            }}
                                            isMobileOnly={this.props.isMobileOnly}
                                            isState={isState}
                                        />
                                    )
                                }).filter(o => o)}
                            </Carousel>
                        </div>
                    </div>
                    <div className="hideForm">
                        <div className="row" style={{ marginTop: 25 }}>
                            {last_prospections.map((prospection, i) => {
                                const rest_surface = prospection.rest_surface
                                if (!rest_surface) {
                                    return null
                                }
                                const currentPosition = prospection.userinfo ? (
                                    prospection.userinfo.currentPosition ===
                                    "Autre"
                                        ? prospection.userinfo
                                              .customCurrentPosition
                                        : prospection.userinfo.currentPosition) : ""
                                const views = prospection.stats
                                const calls = prospection.phone_stats
                                const proposals = prospection.proposal_stats
                                const funding =
                                    prospection.funding ||
                                    (prospection.userinfo && prospection.userinfo.status === 2)
                                const lease = prospection.lease
                                const lease_attributes =
                                    prospection.lease_attributes
                                const price = prospection.price
                                const price_attributes =
                                    prospection.price_attributes
                                const display_number =
                                    prospection.display_number
                                const date = moment(
                                    prospection.last_modified_ts
                                )
                                    .locale("fr")
                                    .format("Do MMMM YYYY")
                                const prospection_id =
                                    prospection.prospection_id
                                const reference_id = prospection.reference_id
                                const id = prospection.id
                                const location = prospection.location
                                const locations = prospection.locations
                                const activity =
                                    prospection.userinfo &&
                                    prospection.userinfo.activityBrands
                                        ? activities.filter(
                                              activity =>
                                                  activity.key ===
                                                  prospection.userinfo
                                                      .activityBrands
                                          )[0].name
                                        : prospection.activity_type
                                            ? activities.filter(
                                                  activity =>
                                                      activity.key ===
                                                      prospection.activity_type
                                              )[0].name
                                            : ""
                                const buy = prospection.buy
                                const is_field = prospection.is_field
                                const description = prospection.description
                                const user_status = prospection.user_status

                                return (
                                    <div
                                        className="col-md-4"
                                        style={{ marginTop: 40 }}
                                        key={i}
                                    >
                                        <ProspectionCard
                                            id={id}
                                            activity={activity}
                                            prospection_id={prospection_id}
                                            reference_id={reference_id}
                                            rest_surface={rest_surface}
                                            date={date}
                                            description={description}
                                            proposal_stats={proposals}
                                            phone_stats={calls}
                                            view_stats={views}
                                            funding={funding}
                                            lease={lease}
                                            lease_attributes={lease_attributes}
                                            price={price}
                                            price_attributes={price_attributes}
                                            display_number={display_number}
                                            location={location}
                                            locations={locations}
                                            user_status={user_status}
                                            buy={buy}
                                            is_field={is_field}
                                            style={{
                                                margin: "auto"
                                            }}
                                            isState={isState}
                                            isMobileOnly={this.props.isMobileOnly}
                                        />
                                    </div>
                                )
                            }).filter(o => o)}
                        </div>
                    </div>
                </section>
                <div className="hideForm">
                    <div
                        className="row"
                        style={{ margin: 0, marginTop: 30, marginBottom: 30 }}
                    >
                        <div className="col" style={{ textAlign: "center" }}>
                            <a
                                className="uec-button-line"
                                href={`/${typesURLMatching['local-commercial']}`}
                            >
                                Voir les recherches de local commercial
                            </a>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ homepage }) => {
    const {
        last_prospections,
        last_prospections_fetching,
        last_prospections_fetched,
        last_prospections_error,
        ssr
    } = homepage

    return {
        last_prospections,
        last_prospections_fetching,
        last_prospections_fetched,
        last_prospections_error,
        ssr
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ lastProspectionsFetch }, dispatch)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LastProspections)
