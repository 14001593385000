import React, { Component, Fragment } from "react"
import _ from "lodash"

class TestimonialCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hover: null
        }
    }

    render() {
        const {
            className,
            style,
            brand,
            brandLogo,
            firstname,
            lastname,
            position,
            testimonial,
            pos
        } = this.props
        return (
            <div
                onMouseOver={() => {
                    this.setState({ hover: pos })
                }}
                onMouseOut={() => {
                    this.setState({ hover: null })
                }}
                className={className}
                style={{
                    background: "#ffffff",
                    borderRadius: 5,
                    maxWidth: 500,
                    padding: 15,
                    height: this.props.noTruncate ? 220 : undefined,
                    minHeight: 160,
                    border: this.props.noTruncate
                        ? undefined
                        : this.state.hover === pos
                            ? "2px solid #0db3ac"
                            : style
                                ? style.border
                                : undefined,
                    ...style
                }}
            >
                <div style={{ display: "flex" }}>
                    <div style={{ width: "80%" }}>
                        <div
                            style={{
                                fontFamily: "Lato, Roboto, Helvetica, Arial, sans-serif",
                                color: "black",
                                fontWeight: "bold",
                                fontSize: 21
                            }}
                        >
                            {_.toUpper(brand)}
                        </div>
                        <div
                            style={{
                                fontFamily: "Lato, Roboto, Helvetica, Arial, sans-serif",
                                color: "black",
                                fontWeight: "bold",
                                fontSize: 13,
                                marginTop: 20
                            }}
                        >
                            {_.upperFirst(_.toLower(firstname))}{" "}
                            {_.toUpper(lastname)}
                            {firstname !== "" || lastname !== ""
                                ? ","
                                : ""}{" "}
                            {position}
                        </div>
                    </div>
                    <div style={{ width: "20%" }}>
                        <img
                            src={brandLogo}
                            alt={brand}
                            style={{
                                height: 60,
                                width: 60,
                                float: "right"
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        fontFamily: "Lato, Roboto, Helvetica, Arial, sans-serif",
                        color: "black",
                        fontWeight: 400,
                        fontSize: 13,
                        marginTop: 15,
                        lineHeight: 1.4
                    }}
                    dangerouslySetInnerHTML={{
                        __html: this.props.noTruncate
                            ? testimonial
                            : this.state.hover === pos
                                ? testimonial
                                : _.truncate(testimonial, {
                                      length: 200,
                                      omission: " ..."
                                  })
                    }}
                />
            </div>
        )
    }
}

export default TestimonialCard
