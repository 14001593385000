import React, { Component } from "react"
import Fade from "react-reveal/Fade"
import brandsLogos from "../datas/brandsLogos"
import { generateObfuscatedLink } from '../helpers/SEO'

class BrandCustomers extends Component {
    render() {
        return (
            <section
                className="container-fluid mr-md-custom2"
                style={{ marginTop: this.props.marginTop || 60 }}
            >
                <div className="row">
                    <div
                        className="col-md-12"
                        style={{
                            fontFamily:
                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                            color: "black"
                        }}
                    >
                        <h2
                            style={{
                                margin: "auto",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: 32
                            }}
                        >
                            Ils nous font confiance
                        </h2>
                        <div
                            style={{
                                fontWeight: "bold",
                                fontSize: 14,
                                lineHeight: 1.3,
                                fontWeight: 500,
                                width: "100%",
                                maxWidth: 500,
                                margin: "auto",
                                textAlign: "center",
                                marginTop: 25,
                                marginBottom: 25
                            }}
                        >
                            <p>
                                Plus de 5 000 enseignes nationales, porteurs de
                                projets, commerçants indépendants, franchisés
                                ont choisi UnEmplacement.com pour communiquer
                                leurs recherches de locaux commerciaux et
                                terrains, sans être visibles des concurrents.
                            </p>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Fade>
                                <div
                                    className="row logos-block"
                                    style={{
                                        width: "100%",
                                        maxWidth: 740,
                                        margin: "auto"
                                    }}
                                >
                                    {brandsLogos.map((entry, i) => {
                                        if (i < 8) {
                                            return (
                                                <div
                                                    key={i}
                                                    className={`col-md-3 col-12 logos${
                                                        i > 2 ? " hideForm" : ""
                                                    }`}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        paddingTop: 20,
                                                        paddingBottom: 20
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            background: `url(${
                                                                entry.logo
                                                            }) no-repeat`,
                                                            backgroundSize:
                                                                "contain",
                                                            backgroundPosition:
                                                                "center center",
                                                            height: "100%",
                                                            width: "100%"
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </Fade>
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: "center"
                                }}
                            >
                                {generateObfuscatedLink(
                                    "/references/enseignes",
                                    "Voir plus",
                                    "uec-button-line",
                                    {
                                        width: 90,
                                        top: -10
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default BrandCustomers
