import { CDN_DOMAIN } from "../config/cdn"

export default [
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/reseau_brokers.png`,
        name: "Réseau Brokers"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/capifrance.png`,
        name: "Capifrance"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/michel_simond.png`,
        name: "Michel Simond"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/orpi_pro.png`,
        name: "Orpi PRO"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/enterprise_immo.png`,
        name: "Enterprise"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/storee_retail.png`,
        name: "Storee Retail"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/le_siege_du_commerce.png`,
        name: "Le Siège du Commerce"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/eol.png`,
        name: "EOL"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/bouygues_immobilier.png`,
        name: "Bouygues Immobilier"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/la_tour_immo.png`,
        name: "La Tour Immo"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/cabinet_hermes.png`,
        name: "Cabinet Hermès"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/arthur_loyd.png`,
        name: "Arthur Loyd"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/retail_et_connexions.png`,
        name: "Retail & Connexions"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/cap_cession.png`,
        name: "Cap Cession"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/groupe_duval.png`,
        name: "Groupe Duval"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/bnp_paribas_real_estate.png`,
        name: "BNP Paribas Real Estate"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/agora_groupe.png`,
        name: "Agora Groupe"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/malsch.png`,
        name: "Malsch"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/alan_peters.png`,
        name: "Alan Peters"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/abault.png`,
        name: "Abault"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/blot_immobilier.png`,
        name: "Blot Immobilier"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/carmila.png`,
        name: "Carmila"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/catella.png`,
        name: "Catella"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/caze_immobilier.png`,
        name: "CAZE Immobilier"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/cbre.png`,
        name: "CBRE"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/france_transactions.png`,
        name: "France Transactions"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/agent_mandataire.png`,
        name: "AgentMandataireCommerce.fr"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/compagnie_des_commerces.png`,
        name: "Compagnie des Commerces"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/compagnie_vauban.png`,
        name: "Compagnie Vauban"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/bhg_conseil.png`,
        name: "BHG Conseil"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/braxton.png`,
        name: "Braxton"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/societe_des_grands_magasins.png`,
        name: "Société des Grands Magasins"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/cushman_and_wakefield.png`,
        name: "Cushman & Wakefield"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/devim_commercialisation.png`,
        name: "DEVIM"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/dugue.png`,
        name: "Dugué"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/eureka.png`,
        name: "Eureka"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/expandcom.png`,
        name: "Expandcom"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/foodstore_partners.png`,
        name: "Foodstore Partners"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/next.png`,
        name: "NEXT"
    },*/
    /* {
        logo: `${CDN_DOMAIN}/static/logos_immo/emplacement_numero_1.png`,
        name: "Emplacement Numéro 1"
    }, */
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/folzer_groupe.png`,
        name: "Folzer Groupe"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/evolis.png`,
        name: "Evolis"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/fiminco.png`,
        name: "Fiminco"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/louis_vacher.png`,
        name: "Louis Vacher"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/mgs.png`,
        name: "MGS"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/ob_consulting.png`,
        name: "OB Consulting"
    },
    /*
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/financiere_immobiliere_bordelaise.png`,
        name: "Financière Immobilière Bordelaise"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/flagship.png`,
        name: "Flagship"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/foodstore_partners.png`,
        name: "Foodstore & Partners"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/galimmo.png`,
        name: "Galimmo"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/groupe_advisor.png`,
        name: "Groupe Advisor"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/groupe_babylone.png`,
        name: "Groupe Babylone"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/groupe_casino.png`,
        name: "Groupe Casino"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/access_retail.png`,
        name: "Access Retail"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/iburoshop.png`,
        name: "Iburoshop"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/immoshops.png`,
        name: "Immo Shops"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/immotransac.png`,
        name: "Immotransac"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/imocom_partners.png`,
        name: "IMOCOM Partners"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/isseo_immobilier.png`,
        name: "Isséo Immobilier"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/jll.png`,
        name: "JLL"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/jml_implantation_commerciale.png`,
        name: "JML Implantation Commerciale"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/jmp_expansion.png`,
        name: "JMP Expansion"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/knight_frank.png`,
        name: "Knight Frank"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/la_zone_de_recherche.png`,
        name: "La zone de recherche"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/financiere_teychene.png`,
        name: "Financière Teychene"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/lamotte_immobilier.png`,
        name: "Lamotte Immobilier"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/les_nouveaux_constructeurs.png`,
        name: "Les nouveaux constructeurs"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/locopro.png`,
        name: "LOCOPRO"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/lutetia_conseil.png`,
        name: "Lutetia Conseil"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/malsch_properties.png`,
        name: "Malsch Properties"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/marciano.png`,
        name: "Marciano"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/mb_conseil.png`,
        name: "MB Conseil"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/ah_conseils.png`,
        name: "AH Conseils"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/123_commerces.png`,
        name: "123 Commerces"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/les_arches_metropole.png`,
        name: "Les Arches Metropole"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/century_21.png`,
        name: "Century 21"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/centrale_du_commerce.png`,
        name: "Centrale du Commerce"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/nexity.png`,
        name: "Nexity"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/offmarket.png`,
        name: "Offmarket"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/optimhome.png`,
        name: "Optimhome"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/p_et_p_partners.png`,
        name: "P&P Partners"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/patrick_atlan_conseil.png`,
        name: "Patrick Atlan Conseil"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/pax_avenue.png`,
        name: "Pax Avenue"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/peureux.png`,
        name: "Peureux"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/mp_immobilier.png`,
        name: "MP Immobilier"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/pic_transactions.png`,
        name: "PIC Transactions"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/pichet_immobilier.png`,
        name: "Pichet Immobilier"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/point_de_vente.png`,
        name: "Point de Vente"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/premium_retail.png`,
        name: "Premium Retail"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/procomm.png`,
        name: "Procomm"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/quartus.png`,
        name: "Quartus"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/realtyz.png`,
        name: "Realty's"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/1001_enseignes.png`,
        name: "1001 Enseignes"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/scamac_immo.png`,
        name: "SCAMAC IMMO"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/solimco.png`,
        name: "SOLIMCO"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/altarea_cogedim.png`,
        name: "Altarea Cogedim"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/swixim.png`,
        name: "Swixim"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/tcc.png`,
        name: "TCC"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/tikva_immobilier.jpg`,
        name: "TIKVA Immobilier"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/tournymeyer.png`,
        name: "Tournymeyer"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/transworld.png`,
        name: "Transworld"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/vinci_immobilier.png`,
        name: "VINCI Immobilier"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/wallpartners.png`,
        name: "Wall Partners"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/2ima.png`,
        name: "Groupe 2IMA"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/3l.jpg`,
        name: "3L"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/aabam.jpg`,
        name: "AABAM"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/accessite.jpg`,
        name: "Accessite"
    },
        {
        logo: `${CDN_DOMAIN}/static/logos_immo/advenis.png`,
        name: "Advenis"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/acd.jpg`,
        name: "A.C.D"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/actifs_entreprises.png`,
        name: "Actifs Entreprises"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/advenis.png`,
        name: "advenis.png"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/affine.png`,
        name: "Affine"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/agence_facade_immobilier.jpg`,
        name: "FACADES Immobilier Commercial"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/altimmo.jpg`,
        name: "Altimmo"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/alto_commerces.jpg`,
        name: "Alto Commerces"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/apsys.png`,
        name: "APSYS"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/arp.jpg`,
        name: "ARP"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/arrow.png`,
        name: "Arrow"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/berge_immobilier.jpg`,
        name: "BERGE Immobilier"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/bonastrat.jpg`,
        name: "Bonastrat"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/budet.jpg`,
        name: "Budet"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/cabinet_cessiopro.png`,
        name: "Cessiopro"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/cap_transactions.png`,
        name: "Cap Transactions"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/caso_patrimoine.png`,
        name: "CASO Patrimoine"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/cbre_gdi.jpg`,
        name: "CBRE GDI"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/ceetrus.png`,
        name: "Ceetrus"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/cegerem.jpg`,
        name: "Cegerem"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/celimo.png`,
        name: "Celimo"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/century_21.jpg`,
        name: "Century 21"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/cle_solutions_immobilieres.jpg`,
        name: "CLE Solutions immobilières"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/concept_ty.jpg`,
        name: "Concept ty"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/constructa.png`,
        name: "Constructa"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/consultim.png`,
        name: "Consultim"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/corinne_ghislain.jpg`,
        name: "Cornine Ghislain"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/devcom.jpg`,
        name: "DEVCOM"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/etixia.png`,
        name: "Etixia"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/fg_immo_conseil.jpg`,
        name: "FG IMMO CONSEIL"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/fonciere_entreprise.jpg`,
        name: "Foncière Entreprise"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/frey.png`,
        name: "Frey"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/gd_expansion.jpg`,
        name: "GD Expansion"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/gouny_starkley.png`,
        name: "Gouny & Starkley"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/groupe_bsi_ogipa.png`,
        name: "Groupe BSI OGIPA"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/groupe_strauss.png`,
        name: "Groupe Strauss"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/immo_mousquetaires.png`,
        name: "Immo mousquetaires"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/indev_real_estate.png`,
        name: "Indev Real Estate"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/korn_immobilier_commercial.png`,
        name: "Korn Immobilier Commercial"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/i2bc.png`,
        name: "i2bc"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/icw.jpg`,
        name: "ICW Immobilier"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/immobilier_prive.jpg`,
        name: "L'Immobilier privé"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/itf_conseil.jpg`,
        name: "ITF Conseil"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/j2si.png`,
        name: "J2SI"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/jouzel.jpg`,
        name: "Jouzel"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/juris_commerce.jpg`,
        name: "Juris Commerce"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/kalstone.png`,
        name: "Kalstone"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/keller_william.png`,
        name: "Keller William"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/keymex_pro.png`,
        name: "Keymex Pro"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/les_allees_du_commerce.png`,
        name: "Les Allées du Commerce"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/lm_conseil.png`,
        name: "LM Conseil"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/la_commerces.png`,
        name: "L&A Commerces"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/la_pierre_parisienne.png`,
        name: "La pierre parisienne"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/liberte_commerce.jpg`,
        name: "Liberté commerce"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/lutetia_conseil.jpg`,
        name: "Lutetia Conseil"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/global_consulting.png`,
        name: "Global Consulting"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/mercialys.png`,
        name: "Mercialys"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/michel_simond.jpg`,
        name: "Michel Simond"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/mohan.png`,
        name: "Mohan Transactions"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/mon_local_pro.jpg`,
        name: "Mon local pro"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/murs_fonds.jpg`,
        name: "Murs & Fonds .COM"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/murs_prives.png`,
        name: "Murs Privés"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/negoshop.png`,
        name: "Negoshop"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/nhood.png`,
        name: "Nhood"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/omnium.png`,
        name: "Omnium"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/opus_capital.png`,
        name: "Opus Capital"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/negovalor.png`,
        name: "Negovalor"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/network.jpg`,
        name: "Network"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/next.jpg`,
        name: "Next - Nouvelle expansion terrestre"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/omnium.png`,
        name: "Omnium"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/orpi_littoral_immobilier.jpg`,
        name: "Orpi Littoral Immobilier"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/paris_seine.png`,
        name: "Paris Seine"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/poste_immo.png`,
        name: "Poste Immo"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/pro_immo_conseil.jpg`,
        name: "Pro Immo Conseil"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/quartus.png`,
        name: "Quartus"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/rezoximo.jpg`,
        name: "Rezoximo"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/sogim.jpg`,
        name: "SOGIM"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/sourcing_invest.jpg`,
        name: "Sourcing Invest"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/sportimmo.png`,
        name: "SPORTIMMO"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/terranae.png`,
        name: "Terranae"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/thomas_broquet_conseil.png`,
        name: "Thomas Broquet Conseil"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/tostain_laffineur.jpg`,
        name: "Tostain & Laffineur"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/de_watou.png`,
        name: "De Watou"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/dh_conseil.png`,
        name: "DH Conseil"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/transaxio.png`,
        name: "Transaxio"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/transmicible.png`,
        name: "Transmicible"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/trianon.png`,
        name: "Trianon Investissement"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/trivium.png`,
        name: "Trivium"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/urban_retail.jpg`,
        name: "Urban Retail"
    },*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/smart_retail.png`,
        name: "Smart Retail"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/pb_retail_partners.png`,
        name: "P&B Retail Partners"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/savills.png`,
        name: "Savills"
    },
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/wallpartners.png`,
        name: "WallPartners"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/WPAC.jpg`,
        name: "WPAC"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/zambon_entreprise.png`,
        name: "Zambon Entreprise"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/h2i.png`,
        name: "H2i"
    },*/
    /*{
        logo: `${CDN_DOMAIN}/static/logos_immo/chr_home.png`,
        name: "CHR Home"
    }*/
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/chevalier_patrimoine.png`,
        name: "Chevalier Patrimoine"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/prime_axe.png`,
        name: "Prime Axe"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/ratp_travel_retail.png`,
        name: "RATP Travel Retail"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/retaylor.png`,
        name: "Retaylor"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/sadone.png`,
        name: "Sadone"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/up_real_estate.png`,
        name: "Up Real Estate"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/visinvest.png`,
        name: "Visinvest"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/vudenhaut.png`,
        name: "Vudenhaut"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/white_stone.png`,
        name: "White Stone"
    },
    {
        logo: `${CDN_DOMAIN}/static/logos_immo/2l_consulting.png`,
        name: "2L Consulting"
    }
]
