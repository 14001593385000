import React, { Component } from "react"
import { CDN_DOMAIN } from "../config/cdn"

class Video extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openYoutubeVideo: false
        }
    }

    render() {
        return (
            <div style={{ position: "relative", height: "100%" }}>
                {!this.state.openYoutubeVideo && (
                    <div
                        style={{
                            position: "absolute",
                            zIndex: 10,
                            top: "calc(50% - 45px)",
                            left: "calc(50% - 45px)"
                        }}
                    >
                        <a
                            href="javascript:void(0)"
                            onClick={() => {
                                this.setState({
                                    openYoutubeVideo: true
                                })
                            }}
                        >
                            <img
                                className="play-video"
                                src={`${CDN_DOMAIN}/static/svg/ico-play.svg`}
                                style={{
                                    height: 90,
                                    width: 90
                                }}
                                alt=""
                            />
                        </a>
                    </div>
                )}
                {this.state.openYoutubeVideo && (
                    <div
                        style={{
                            position: "absolute",
                            zIndex: 10,
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0
                        }}
                    >
                        {/*<iframe
                            width="100%"
                            height="100%"
                            src="https://www.youtube.com/embed/Ls5tllmZFP4?autoplay=1&feature=emb_logo"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />*/}
                        <iframe
                            src="https://player.vimeo.com/video/718189683?h=d909ba5c3d"
                            width="100%"
                            height="100%"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                )}
                <img
                    src={`${CDN_DOMAIN}/static/events-4.jpg`}
                    alt="Evènement: Petit déjeuner"
                    style={{
                        width: "100%",
                        zIndex: 1
                    }}
                />
            </div>
        )
    }
}

export default Video
