import React, { Component } from "react"
import Fade from "react-reveal/Fade"
import prosLogos from "../datas/prosLogos"
import { CDN_DOMAIN } from "../config/cdn"
import { generateObfuscatedLink } from "../helpers/SEO"

class ProCustomers extends Component {
    render() {
        return (
            <Fade>
                <div
                    className="row"
                    style={{ marginLeft: 0, marginRight: 0, marginTop: 10 }}
                >
                    <div
                        className="col-md-6 zero-padding-mobile"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            paddingRight: 40,
                            marginBottom: 20
                        }}
                    >
                        <div style={{ width: "100%" }}>
                            <div
                                className="row logos-block"
                                style={{
                                    width: "100%",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    marginLeft: 0,
                                    marginRight: 0
                                }}
                            >
                                {prosLogos.map((entry, i) => {
                                    if (i < 9) {
                                        return (
                                            <div
                                                key={i}
                                                className={`col-md-4 col-12 logos${
                                                    i > 2 ? " hideForm" : ""
                                                }`}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    paddingTop: 20,
                                                    paddingBottom: 20
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        background: `url(${
                                                            entry.logo
                                                        }) no-repeat`,
                                                        backgroundSize:
                                                            "contain",
                                                        backgroundPosition:
                                                            "center center",
                                                        height: "100%",
                                                        width: "100%"
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div
                                className="row"
                                style={{
                                    width: "100%",
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    marginLeft: 0,
                                    marginRight: 0
                                }}
                            >
                                <div
                                    className="col"
                                    style={{
                                        position: "relative",
                                        textAlign: "center"
                                    }}
                                >
                                    {generateObfuscatedLink(
                                        "/references/professionnels-immobilier",
                                        "Voir plus",
                                        "uec-button-line",
                                        {
                                            width: 90,
                                            top: -10
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 hideForm">
                        <figure>
                            <Fade>
                                <img
                                    src={`${CDN_DOMAIN}/static/svg/carte-france.svg`}
                                    style={{ marginLeft: 20, marginRight: 20 }}
                                    alt=""
                                />
                            </Fade>
                        </figure>
                    </div>
                </div>
            </Fade>
        )
    }
}

export default ProCustomers
